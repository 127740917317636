<template>
  <div class="ai-content-view">
    <div class="ai-content-view__header">
      <button
        @click="sidebarDrawer()"
        class="ai-content-view__header--sidebar-btn"
      >
        <img src="@/assets/icons/menu.svg" />
      </button>
      <div class="ai-content-view__header--logo">
        <img src="@/assets/icons/glocal-ai-icon-navbar-black.svg" />
        <h4>Glocal AI</h4>
      </div>
      <!-- <button
          class="ai-content-view__header--invite-btn"
          @click="inviteToAiChat()"
      >
        <img src="@/assets/icons/forward_to_inbox.svg" alt="icon">
        Invite AI chats
      </button> -->
    </div>
    <div class="ai-content-view__body" v-if="chatSkeletonLoader && paramSessionId">
      <a-skeleton
        v-for="index in 3"
        :key="index"
        class="ai-content-view__body--message-card-skeleton"
        active
        :paragraph="{
          rows: 3,
        }"
      />
    </div>
    <div class="ai-content-view__body" v-if="!chatSkeletonLoader" id="chatContainer">
      <div class="ai-content-view__body--default-message" :style="{ position: sessionChat.length === 0 ? 'absolute' : 'relative', bottom: sessionChat.length === 0 ? '0' : '0' }">
        <img src="@/assets/icons/glocal-ai-icon-navbar-black.svg" alt="Icon">
        <h1 class="title">Welcome to Glocal AI</h1>
         <a-row :gutter="[18, 10]">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Tell me some positive news stories</h1>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Summarize key national security trends</h1>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Most recent election poll results</h1>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Tell me the top sports news updates</h1>
            </div>
          </a-col>
        </a-row>
      </div>
      <div
        class="ai-content-view__body--answer-question"
        v-for="message in sessionChat"
        :key="message.id"
      >
        <div v-if="message.by === 'user'" class="my-question">
          <img
            :src="message.user.profile_picture"
          />
          <h5>{{ message.message }}</h5>
        </div>
        <ai-comment-card v-if="message.by === 'bot'" :botMessage=message></ai-comment-card>
      </div>
      <ai-comment-card v-if="botResponseLoader"></ai-comment-card>
    </div>
    <div class="ai-content-view__footer">
      <div class="ai-content-view__footer--comment">
        <img
          :src="displayPicture"
        />
        <textarea
          name=""
          id="commentTextarea"
          cols="30"
          rows="1"
          placeholder="Submit inquiry..."
          v-model="comment"
          @keyup.enter="createComment"
        ></textarea>
        <div class="buttons">
          <a-button
            class="btn"
            :class="{ disabled: isDisabled }"
            :disabled="isDisabled"
            @click="createComment()"
          >
            <i class="icon icon-send"></i>
          </a-button>
        </div>
      </div>
    </div>
  </div>
  <sidebar-mobile-view-drawer
    :drawerVisibility="drawerVisibility"
    @close-drawer="drawerVisibility = false"
  ></sidebar-mobile-view-drawer>
  <invite-to-ai-chat-modal
   :visible="inviteMoalVisible"
   @close-modal="closeModal()"
  >
  </invite-to-ai-chat-modal>
</template>

<script>
import AiCommentCard from "./AICommentCard.vue";
import SidebarMobileViewDrawer from "./SideBarMobileViewDrawer.vue";
import { mapGetters } from 'vuex'
import inviteToAiChatModal from "./InviteToAiChatMoal.vue"

export default {
  emits: ["close-drawer"],
  data() {
    return {
      comment: "",
      drawerVisibility: false,
      comments: [],
      inviteMoalVisible: false
    };
  },
  components: {
    AiCommentCard,
    SidebarMobileViewDrawer,
    inviteToAiChatModal
  },
  computed: {
    ...mapGetters({
      sessionChat: 'aiChat/sessionChat',
      chatSectionSkeleton: 'aiChat/chatSectionSkeleton',
      botResponseLoader: 'aiChat/botResponseLoader'
    }),
    isDisabled() {
      return this.comment.trim() === "";
    },

    selectedSession() {
      return this.$store.getters['aiChat/activeSession'];
    },
    displayPicture() {
      return Object.keys(this.$store.getters["profile/userInformation"]).includes("profile")
        ? this.$store.getters["profile/userInformation"].profile.profile_picture
        : "";
    },
    chatSkeletonLoader() { 
      return this.chatSectionSkeleton
    },
    paramSessionId() { 
      return this.$route.params.id
    },
    sessionChatLength() { 
      return this.sessionChat.length
    }
  },
  watch: {
    sessionChatLength(newLength) { 
      if (newLength > 0)
        this.scrollToBottom()
    }
  },
  methods: {
    sidebarDrawer() {
      this.drawerVisibility = true;
    },
    async createComment() {
      if (this.selectedSession) {
        console.log('this.selectedSession',this.selectedSession);
        const commentDetails = {
          message: this.comment,
          chat_bot_session: this.selectedSession.id 
        }
        await this.$store.dispatch("aiChat/createMessage", commentDetails) 
        this.comment = ''
        this.scrollToBottom()
      }
      else {
        console.log('else');
        this.$store.dispatch("aiChat/createSession", this.comment)
        this.comment = ''
      }
    },
    scrollToBottom() {
      const chatContainer = document.querySelector("#chatContainer");
      const self = this;
      if (chatContainer) {
          chatContainer.scrollTop = chatContainer.scrollHeight - 100;
        }
    },
    inviteToAiChat() {
      this.inviteMoalVisible = true
    },
    closeModal() {
      this.inviteMoalVisible = false
    },
  },
};
</script>

<style lang="scss">
.ai-content-view {
  padding: 0 4rem;
  height: 100%;
  &__header {
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &--sidebar-btn {
      display: none;
      margin-right: 2.5rem;
      line-height: normal;
      padding: 0;
      height: fit-content;
      width: fit-content;
      border: none;
      background-color: transparent;
      color: $color-black;
      outline: none;
      cursor: pointer;
      img {
        width: 4rem;
      }
      @include respond(tab-port) {
        display: block;
      }
    }
    &--logo {
      display: flex;
      align-items: center;
      width: fit-content;
      img {
        width: 4.2rem;
        height: auto;
        margin-right: 1rem;
      }
      h4 {
        font-size: 2.4rem;
        font-family: $font-primary-medium;
        margin-bottom: 0;
        color: $color-black;
        line-height: 2.8rem;
        margin-top: 0.7rem;
      }
    }
    &--invite-btn {
      color: $color-white;
      font-family: $font-primary-medium;
      font-size: 1.6rem;
      background-color: $color-primary;
      padding: 0 2rem;
      height: 3.9rem;
      margin-left: auto;
      line-height: normal;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 2rem;
        height: auto;
        margin-right: 0.9rem;
        margin-top: 0.3rem;
      }
    }
  }
  &__body {
    max-width: 86rem;
    margin: 0 auto;
    padding: 1.5rem 1rem 0 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 33rem);
    position: relative;
    display: block;
    // display: flex;
    // justify-content: flex-end;
    // flex-direction: column;
    &--answer-question {
      .my-question {
        display: flex;
        align-items: flex-start;
        img {
          width: 3.8rem;
          height: 3.8rem;
          border-radius: 100%;
          object-fit: fill;
          margin-right: 2.5rem;
          background-color: #e1e1e1;
        }
        h5 {
          font-size: 1.8rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          color: $color-black;
          line-height: 2.4rem;
          margin-top: 0.8rem;
        }
      }
    }
    &--message-card-skeleton {
      margin-bottom: 2rem;
      .ant-skeleton-content {
        display: flex;
        align-items: flex-start;
        .ant-skeleton-title {
          width: 4rem !important;
          height: 4rem !important;
          border-radius: 100% !important;
          margin: 0;
          margin-right: 2rem;
        }
        .ant-skeleton-paragraph {
          width: calc(100% - 6rem);
          margin: 0.5rem 0 0;
          li {
            margin: 0 0 1.5rem;
            height: 1.6rem;
          }
        }
      }
    }
    &--default-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 5rem;
      width: 100%;
      img {
        width: 8rem;
        height: auto;
        margin-bottom: 3rem;
      }
      .title {
        font-size: 3rem;
        font-family: $font-primary-bold;
        margin-bottom: 1.5rem;
        color: $color-black;
        line-height: 3.3rem;
        text-align: center;
      }
      .ant-row {
        width: 100%;
        margin-top: 1rem;
      }
      .chat-message-card {
        border: 1px solid $color-dark-grey-5;
        padding: 1.5rem;
        border-radius: 1rem;
        h1 {
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          color: $color-black;
          line-height: 2.2rem;
          text-align: left;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
                  line-clamp: 1; 
          -webkit-box-orient: vertical;
          width: 100%;
        }
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #f4f4f4;
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: rgb(224, 224, 224);
      border-radius: 3rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
  }
  &__footer {
    position: absolute;
    bottom: 3rem;
    width: calc(100% - 8rem);
    padding: 3rem 0;
    background-color: transparent;
    background-color: $color-white;
    &--comment {
      background-color: #eeeeee;
      padding: 0 1.3rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      max-width: 88rem;
      margin: 0 auto;
      img {
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 100%;
        object-fit: fill;
      }
      textarea {
        padding: 1.4rem 1.5rem;
        margin: 1rem 0;
        border: none;
        font-size: 1.4rem;
        font-family: $font-primary;
        box-shadow: none;
        outline: none;
        line-height: 1.8rem;
        color: $color-black;
        background-color: transparent;
        resize: none;
        width: 100%;
        &::placeholder {
          color: #868686;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        .disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .ant-btn {
          width: 8rem;
          background-color: $color-primary;
          border: none;
          outline: none;
          height: 4rem;
          font-size: 1.4rem;
          font-family: $font-primary-medium !important;
          color: $color-white;
          border-radius: 100px !important;
          span {
            line-height: normal;
          }
          .anticon {
            .anticon-spin {
              font-size: 1.8rem;
              color: $color-primary;
            }
          }
          &:hover,
          &:focus {
            background-color: $color-primary;
            color: $color-white;
          }
          .icon {
            font-size: 2rem;
            display: block;
            color: $color-white;
          }
        }
      }
    }
  }
}
</style>
